@use '@angular/material' as mat;

.mat-mdc-dialog-content {
    .mat-mdc-table {
        --spacing: 24px;
    }
}

.mat-mdc-table {
    --spacing: 16px;

    thead tr {
        height: unset;
    }

    th {
        padding-block: var(--spacing-xs);
        vertical-align: bottom;
        color: var(--color-grey-4);
    }

    .mat-mdc-cell:not(.mat-column-actions) {
        justify-content: space-between;
    }

    .mat-mdc-header-cell:first-child {
        padding-left: var(--spacing);
    }

    .mat-mdc-cell:first-child {
        padding-left: var(--spacing);
    }

    .mat-mdc-header-cell:last-child {
        padding-right: var(--spacing);
    }

    .mat-mdc-cell:last-child {
        padding-right: var(--spacing);
    }

    &:not(.no-click) {
        .mat-mdc-row {
            transition: background 250ms ease-in-out;
        }

        .header, .mat-mdc-row {
            cursor: pointer;
        }
    }
}
