.dir-button {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    border: 1px solid var(--color-grey-2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--color-grey-0);
    border-radius: var(--border-radius);
    color: var(--color-grey-4);
    font-weight: 500;
    padding-inline: calc(var(--spacing-m) - 1px);
    padding-block: calc(var(--spacing-xs) - 1px);
    white-space: nowrap;
    cursor: pointer;

    &__icon {
        padding-inline: calc(var(--spacing-xs) - 1px);

        & .mat-icon {
            display: block;
        }
    }

    &:hover {
        background-image: linear-gradient(0deg, var(--color-support-hover-default), var(--color-support-hover-default));
    }

    &:active {
        background-image: linear-gradient(0deg, var(--color-support-click-default), var(--color-support-click-default));
    }

    &[disabled], &__disabled {
        border-color: transparent;
        color: var(--color-grey-3);
        box-shadow: none;
        pointer-events: none;
    }

    &__brand {
        color: var(--color-grey-0);
        background-color: var(--color-brand-1);
        border-color: transparent;

        &:hover {
            background-image: linear-gradient(0deg, var(--color-support-hover-prominent), var(--color-support-hover-prominent));
        }

        &:active {
            background-image: linear-gradient(0deg, var(--color-support-click-prominent), var(--color-support-click-prominent));
        }

        &[disabled], &__disabled {
            color: var(--color-grey-3);
            background-color: var(--color-grey-2);
        }
    }

    &__negative {
        color: var(--color-grey-0);
        background-color: var(--color-support-negative);
        border-color: transparent;

        &:hover {
            background-image: linear-gradient(0deg, var(--color-support-hover-prominent), var(--color-support-hover-prominent));
        }

        &:active {
            background-image: linear-gradient(0deg, var(--color-support-click-prominent), var(--color-support-click-prominent));
        }

        &[disabled], &__disabled {
            color: var(--color-grey-3);
            background-color: var(--color-grey-2);
        }
    }
}