.dir-link {
    position: relative;
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    justify-content: center;

    padding-block: var(--spacing-xs);

    color: var(--color-brand-1);

    &::after {
        content: '';
        position: absolute;
        left: calc(-1 * var(--spacing-s));
        top: 0;
        width: calc(100% + 2 * var(--spacing-s));
        height: 100%;
        border-radius: var(--border-radius);
    }

    &:hover::after {
        background-color: var(--color-support-hover-default);
    }

    &:active::after {
        background-color: var(--color-support-click-default);
    }

    &__disabled, &:disabled {
        color: var(--color-grey-3);
    }
}