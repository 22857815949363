.dir-select {
    display: block;
    border: 1px solid var(--color-grey-2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    border-radius: var(--border-radius);
    color: var(--color-grey-4);
    padding-inline-start: calc(var(--spacing-s) - 1px);
    padding-inline-end: calc(var(--spacing-s) + var(--spacing-xs) + 1.25rem - 1px);
    padding-block: calc(var(--spacing-xs) - 1px);

    &:focus-visible {
        border-color: var(--color-grey-4);
    }

    &__full-width {
        width: 100%;
    }
}