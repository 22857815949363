@use '@angular/material' as mat;

:root {
    --spacing-xs: .5rem;
    --spacing-s: 1rem;
    --spacing-m: 2rem;
    --spacing-l: 4rem;
    --border-radius: .5rem;
    --border-radius-l: 1rem;

    --color-grey-0: rgba(255, 255, 255, 1);
    --color-grey-1: rgba(250, 251, 252, 1);
    --color-grey-2: rgba(211, 215, 224, 1);
    --color-grey-3: rgba(122, 133, 153, 1);
    --color-grey-4: rgba(31, 37, 51, 1);

    --color-brand-1: rgba(94, 135, 141, 1);

    --color-support-hover-default: rgba(0, 0, 0, 0.04);
    --color-support-click-default: rgba(0, 0, 0, 0.08);
    --color-support-hover-prominent: rgba(0, 0, 0, 0.08);
    --color-support-click-prominent: rgba(0, 0, 0, 0.16);

    --color-support-negative: rgba(235, 76, 23, 1);
    --color-support-positive: rgba(49, 193, 20, 1);
    --color-support-warning: rgba(229, 178, 23, 1);

    line-height: 1.5;
}

$dark-primary-text: rgba(31, 37, 51, 1);

$background-color: #fff;

$vb-amber: (
        //50: hsl(40, 99%, 100%),
        100: hsl(39, 99%, 94%),
        200: hsl(39, 99%, 84%),
        300: hsl(40, 99%, 74%),
        400: hsl(39, 99%, 67%),
        500: rgba(94, 135, 141, 1),
        600: hsl(36, 99%, 59%),
        700: hsl(32, 99%, 59%),
        800: hsl(28, 99%, 59%),
        900: hsl(20, 99%, 59%),
        A100: #ffe57f,
        A200: #ffd740,
        A400: #ffc400,
        A700: #ffab00,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: #FFF,
                600: rgba(black, 0.87),
                700: rgba(black, 0.87),
                800: rgba(black, 0.87),
                900: rgba(black, 0.87),
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: rgba(black, 0.87),
        )
);

$vb-brand: (
        50: rgb(232, 241, 243, 1),
        100: rgb(204, 220, 222, 1),
        200: rgb(174, 196, 199, 1),
        300: rgb(142, 172, 176, 1),
        400: rgb(118, 153, 158, 1),
        500: rgba(94, 135, 141, 1),
        600: rgb(83, 119, 124, 1),
        700: rgb(68, 98, 103, 1),
        800: rgb(54, 79, 83, 1),
        900: rgb(38, 57, 60, 1),
        A100: #d7ccc8,
        A200: #bcaaa4,
        A400: #8d6e63,
        A700: #5d4037,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: white,
                A700: white,
        )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vb-app-primary: mat.define-palette($vb-amber);
$vb-app-accent: mat.define-palette($vb-brand);

// The warn palette is optional (defaults to red).
$vb-app-warn: mat.define-palette(mat.$red-palette);

$vb-app-typography: mat.define-typography-config(
    $font-family: 'Roboto, sans-serif',
    $headline-1: mat.define-typography-level(32px, 1.01, 700),
    $headline-2: mat.define-typography-level(32px, 1.01, 700),
    $headline-3: mat.define-typography-level(24px, 1.33, 700),
    $headline-4: mat.define-typography-level(24px, 1.33, 700),
    $headline-5: mat.define-typography-level(24px, 1.33, 700),
    $headline-6: mat.define-typography-level(24px, 1.33, 700),
    $subtitle-2: mat.define-typography-level(18px, 1.36, 700),
    $subtitle-1: mat.define-typography-level(14px, 1.71, 700),
    $body-1: mat.define-typography-level(16px, 1.5, 500),
    $body-2: mat.define-typography-level(16px, 1.5, 500),
    $caption: mat.define-typography-level(14px, 1.33, 400),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$vb-app-theme: mat.define-light-theme((
        color: (
                primary: $vb-app-primary,
                accent: $vb-app-accent,
                warn: $vb-app-warn,
                typography: $vb-app-typography
        )
));
