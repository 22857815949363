.vertical-tabs {
  height: 100%;

  & > .mat-mdc-tab-group {
      flex-direction: row;
      gap: var(--spacing-m);
      height: 100%;

      & > .mat-mdc-tab-header {
          border-bottom: none;

          .mat-mdc-tab-list {
              padding: 0;
          }

          .mat-mdc-tab-header-pagination {
              display: none;
          }

          .mat-mdc-tab-labels {
              flex-direction: column;
          }

          .mdc-tab {
              --_color: var(--color-grey-3);
              --_badge-color: var(--color-grey-3);
              padding: var(--spacing-s);
              border-radius: var(--border-radius);
              overflow: hidden;

              &:hover {
                  background-color: var(--color-support-hover-default);
              }

              &__ripple {
                  position: absolute;
              }

              &__content {
                  justify-content: start;
                  width: 100%;
                  height: fit-content;
              }

              &__text-label {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  gap: var(--spacing-xs);

                  span {
                      color: var(--_color);
                  }

                  .badge {
                      background-color: var(--_badge-color);
                  }
              }

              &--active {
                  --_badge-color: var(--color-brand-1);
                  --_color: var(--color-grey-4);
              }
          }

          .mdc-tab-indicator {

              &__content--underline {
                  height: 100%;
                  border: none;
                  background-color: var(--color-support-click-default);
              }
          }
      }
  }

  .mat-mdc-ink-bar {
    display: none;
  }

  .mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
  }

    .mat-mdc-tab-body.mat-mdc-tab-body-active,
    .mat-mdc-tab-body-wrapper,
    .mat-mdc-tab-body-content, {
        overflow: visible;
    }
}

.horizontal-tabs {

  .mat-mdc-tab-group {
    flex-direction: initial;
    display: initial;
    height: 100%;
  }

    .mat-mdc-tab-header {
        position: relative;
        margin-block: var(--spacing-s);

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            border-bottom: 2px solid var(--color-grey-2);
        }
    }

    .mat-mdc-tab-labels {
        flex-direction: initial;
        gap: var(--spacing-m);

        .mdc-tab {
            padding: 0;
            min-width: unset;
            height: fit-content;
            padding-block: var(--spacing-xs);

            &__text-label {
                line-height: 1.5;
                color: var(--color-grey-3);
            }

            .mdc-tab-indicator {

                &__content--underline {
                    border-top-width: 2px;
                }
            }

            &__ripple {
                display: none;
            }

            .mat-ripple {
                display: none;
            }
        }

        .mat-mdc-tab.mdc-tab:hover,
        .mat-mdc-tab.mdc-tab:focus {
            background: none;

            .mdc-tab__text-label {
                color: var(--color-grey-4);
            }
        }

        .mat-mdc-tab.mdc-tab--active {
            .mdc-tab__text-label {
                color: var(--color-grey-4);
            }
        }
    }

  .mat-mdc-ink-bar {
    display: initial;
  }

  .mat-mdc-tab-body-wrapper {
    flex: initial;
  }

  .mat-mdc-tab-label-container {
    border-right: unset;
  }
}