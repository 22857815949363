@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@import '@material/theme/theme';
/* You can add global styles to this file, and also import other style files */
@import 'theme/theme';
@import 'theme/component-overrides.scss';

@import '/src/app/directives/ui/button/button.directive';
@import '/src/app/directives/ui/link/link.directive';
@import '/src/app/directives/ui/inputs/select.directive';
@import '/src/app/directives/ui/inputs/input.directive';
@import '/src/app/directives/ui/card/card.directive';
@import '/src/app/directives/ui/label/label.directive';

html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-grey-4);
}

.left-column-list {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-s);
    gap: var(--spacing-s);
    background-color: var(--color-grey-1);
    height: 100%;
    overflow: auto;
}

.left-column-list-item:has(.selected) {
    position: sticky;
    top: 0;
}

.list-bubble {

    .list-bubble-image {
        width: 3rem;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        border-radius: var(--border-radius);
        background-color: var(--color-grey-2);
        color: var(--color-grey-3);
        object-fit: cover;
    }

    .list-bubble-text {
        flex-basis: 100%;

        .timestamp, .time-left {
            color: var(--color-grey-3);
        }
    }
}

.simple-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    padding: var(--spacing-m);
    width: min-content;
    min-width: 100%;
    max-height: calc(100vh - 2 * var(--spacing-s));

    .actions {
        display: flex;
        gap: var(--spacing-s);
    }

    button {
        width: max-content;
    }

    .close-button {
        position: absolute;
        top: var(--spacing-m);
        right: var(--spacing-m);
        cursor: pointer;
    }
}

.note-card {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
    padding-inline: var(--spacing-xs);
}

table {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;

    th, td {
        padding: 0;
        padding-bottom: var(--spacing-xs);
    }

    th {
        padding-right: var(--spacing-s);
        text-align: left;
        vertical-align: top;
        font-weight: 400;
        color: var(--color-grey-3);
    }
}

label {
    display: inline-flex;
    gap: var(--spacing-xs);
}

//label:has( + input, + select, + textarea, + .mat-mdc-form-field),
//legend {
//    margin-bottom: var(--spacing-xs);
//    font-size: 14px;
//    font-weight: 500;
//
//}

.mat-error {
    color: var(--color-support-negative);
}

.dont-use {
    color: var(--color-support-negative);
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
}

input:focus, button:focus {
    outline: none;
}

//input:-webkit-autofill,
//input:-internal-autofill-selected
//{
//    & {
//        -webkit-box-shadow: 0 0 0px 1000px #F6F7F9 inset;
//        background-color: #F6F7F9;
//    }
//}

.button-text {
    color: var(--color-grey-4);
}

.mat-mdc-list-base {
    &.mat-mdc-list-base .mat-mdc-list-item .mat-list-text>*, &.mat-list-base .mat-list-option .mat-list-text>* {
        font-size: 1rem;
    }
}

.ng-invalid.ng-dirty.ng-touched {
    @apply border-danger;
}

.mat-mdc-tooltip.tooltip {
    font-size: 1rem;
    max-width: 75vw;
    padding: 1rem;
    line-height: 1.25rem;
}

@layer base {
    #app-root {

        hr {
            @apply text-gray-darker;
        }

        .mat-mdc-menu-item {
            @apply flex items-center;
        }

        .mat-typography.mat-typography {
            h1, h2, h3, h4, p {
                @apply m-0;
            }
        }

        .pulse {
            @apply absolute block top-0 left-0 right-0 bottom-0 rounded-full border border-double;
            @apply border-seafoam-disabled;
        }

        h1 {
            font-size: 2rem;
            line-height: 1.25;
            font-weight: 700;
        }

        h2 {
            font-size: 2rem;
            line-height: 1.25;
            font-weight: 700;
        }

        h3 {
            font-size: 1.5rem;
            line-height: 1.5;
            font-weight: 700;
        }


        .mat-mdc-list-base {

            &.mat-mdc-selection-list {
                display: inherit;
            }
        }
    }

}

@layer components {
    table.themed-table {
        td.mat-mdc-cell {

        }
        tr:not([mat-header-row]) {
            @apply transition-colors hover:bg-gray-border;
        }
    }
}
