.dir-card {
    display: flex;
    gap: var(--spacing-s);
    align-items: center;

    border-radius: var(--border-radius-l);

    padding-inline: var(--spacing-m);
    padding-block: var(--spacing-s);

    background-color: var(--color-grey-0);
    box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.08);
    outline-offset: -1px;
    outline-color: var(--color-grey-3);

    & > .mat-icon {
        color: var(--color-grey-3);
    }

    &__success {
        background-image: linear-gradient(134.52deg, rgba(49, 193, 20, 0.08) 0%, rgba(49, 193, 20, 0) 100%);
        outline-color: var(--color-support-positive);

        & .mat-icon {
            color: var(--color-support-positive);
        }
    }

    &__error {
        background-image: linear-gradient(134.52deg, rgba(235, 76, 23, 0.08) 0%, rgba(235, 76, 23, 0) 100%);
        outline-color: var(--color-support-negative);

        & .mat-icon {
            color: var(--color-support-negative);
        }
    }

    &__warning {
        background-image: linear-gradient(134.52deg, rgba(235, 182, 23, 0.08) 0%, rgba(235, 182, 23, 0) 100%);
        outline-color: var(--color-support-warning);

        & .mat-icon {
            color: var(--color-support-warning);
        }
    }

    &--selected {
        box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.24);
        outline-width: 1px;
        outline-style: solid;
    }

    &__interactive {

        cursor: pointer;

        &:hover {
            box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.24);
        }
    }
}