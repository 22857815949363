.dir-input {
    display: block;

    border: 1px solid var(--color-grey-2);
    border-radius: var(--border-radius);
    padding-inline: calc(var(--spacing-s) - 1px);
    padding-block: calc(var(--spacing-xs) - 1px);

    &:focus {
        border: 1px solid var(--color-grey-4);
        outline: none;
        box-shadow: none;
    }

    &__disabled, &:disabled {
        color: var(--color-grey-2);
        pointer-events: none;
    }

    &__full-width {
        width: 100%;
    }

    &[type="checkbox"] {
        display: inline-block;

        border-radius: 6px;
        border: 1px solid var(--color-grey-2);
        padding: 0;
        width: 1.5em;
        height: 1.5em;

        color: var(--color-brand-1);
        accent-color: var(--color-brand-1);
    }

    &[type="radio"] {
        display: inline-block;

        border-radius: 100%;
        border: 1px solid var(--color-grey-2);
        padding: 0;
        width: 1.5em;
        height: 1.5em;

        color: var(--color-brand-1);
        accent-color: var(--color-brand-1);
    }

    &.ng-invalid.ng-touched {
        border-color: var(--color-support-negative);
    }
}