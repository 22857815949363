@import "variables";

.mdc-notched-outline__trailing, .mdc-notched-outline__notch, .mdc-notched-outline__leading {
    border-width: 0 !important;
    outline: none !important;
}

.mat-mdc-dialog-component-host > div {

}

.mdc-tab {
    flex-grow: 0 !important;
}

.mat-mdc-text-field-wrapper {
    @apply bg-input-background rounded border border-input-border items-center;
}

mat-paginator.mat-mdc-paginator {
    background: none;
}

// Resets are needed after upgrading from angular material 14 to 15
// TODO Find another way to override these values
@mixin custom-theme-overrides() {

    .mdc-tab  {
        font-family: map-get($vb-app-typography, "$headline-3") !important;
        font-weight: 500 !important;
    }
}
