mat-accordion.mat-accordion.mat-accordion-multi {
    .mat-expansion-panel {
        border-radius: var(--border-radius-l);
        box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.08);
        padding: 0;
    }
}

mat-expansion-panel.mat-expansion-panel {
    border-radius: var(--border-radius-l);
    padding: 0;
}

mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.08);
}