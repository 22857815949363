mat-form-field.mat-mdc-form-field {
    display: flex;

    .mdc-text-field.mat-mdc-text-field-wrapper {
        background-color: var(--color-grey-0);
        border: 1px solid var(--color-grey-2);
        border-radius: var(--border-radius);
        padding-inline-start: calc(var(--spacing-s) - 1px);

        .mat-mdc-form-field-infix {
            padding-top: calc(var(--spacing-xs) - 1px);
            padding-bottom: calc(var(--spacing-xs) - 1px);
            min-height: auto;
        }

        .mat-mdc-form-field-icon-prefix {
            padding-block: calc(var(--spacing-xs) - 1px);
            padding-inline-end: var(--spacing-xs);
            min-height: auto;

            .mat-icon {
                padding: 0;
            }
        }

        .mdc-floating-label {
            background-color: var(--color-grey-0);
            top: 19px;
            margin-inline-start: -.25em;
            padding-inline: .25em;
            max-width: unset;

            &--float-above {
                --mat-mdc-form-field-label-transform: translateY(-27px) scale(.75);
            }
        }

        &:focus-within {
            border: 1px solid var(--color-grey-4);
        }
    }

    .mdc-icon-button {
        margin-inline: var(--spacing-xs);
        padding: calc(var(--spacing-xs) - 1px);
        width: unset;
        height: unset;

        color: var(--color-grey-4);

        .mat-mdc-button-touch-target {
            width: 100%;
            height: 100%;
        }
    }

}

.date-range-input-form-clear {
    position: absolute;
    display: grid;
    place-items: center;
    z-index: 1;
    background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
    cursor: pointer;
    right: 3rem;
    margin: .5rem;
    color: var(--color-grey-4);
}